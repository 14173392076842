import React, { Suspense, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useGLTF } from "@react-three/drei";

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

export default function Model(props) {
  const group = useRef();
  //group.current.rotation.x = 90;
  const { nodes, materials } = useGLTF("/logo.gltf");

  useFrame(() => (group.current.rotation.y += 0.01));
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BezierCircle001.geometry}
        material={materials["Material.002"]}
        scale={[1.05, 1.05, 1.05]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  );
}

useGLTF.preload("/logo.gltf");

// const Mesh = ({ position, args, color }) => {
//   const mesh = useRef(null);
//   //const logo = useGLTF("src/components/Landing/SpinningLogo/armchairGray.gltf");
//   useFrame(() => (mesh.current.rotation.y += 0.01));

//   return (
//     <mesh castShadow position={position} ref={mesh}>
//       <boxBufferGeometry attach="geometry" args={args} />
//       <meshStandardMaterial attach="material" color={color} />
//     </mesh>
//   );
// };
